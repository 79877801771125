<template>
  <div>
    <h4 class="text-right">SALDO ATUAL</h4>
    <h3 class="block text-right title-balance">{{wallet.balance | currency }}</h3>
    <div class="page-heading relative">
      <h2 class="no-margin">{{wallet.name != null ? wallet.name : '...'}}</h2>
      <div class="input-group pull-right" style="width: auto;">
        <div class="input-group-append drop-down arrow-center black">
          <a class="btn btn-default btn-sm ml-5 inherit btn-heading btn-account-details" type="button" @click="$router.go(-1)" style="border-top-left-radius: 3px; border-bottom-left-radius: 3px;"><span><i class="mdi mdi-arrow-left"></i></span> Voltar</a>
          <a class="btn btn-default btn-sm ml-5 inherit btn-heading btn-account-details" type="button" @click="editAccount('edit-account-modal')" v-tooltip.top="`Editar`"><span><i class="fa fa-edit"></i></span></a>
          <a class="btn btn-default  btn-sm ml-5 inherit btn-heading btn-account-details" type="button" @click="removeAccount" v-tooltip.top="`Remover`"><span><i class="fa fa-trash"></i></span></a>
          <a class="btn btn-default ml-5 inherit btn-heading btn-sm btn-account-details" type="button" @click="goImport(wallet)" v-tooltip.top="`Conciliação bancária`"><span><i class="fa fa-university"></i></span></a>
          <a class="btn btn-default ml-5 inherit btn-heading btn-sm btn-account-details" type="button"  @click="createTransaction('create-transaction-modal')" v-tooltip.top="`Transferências`"><span><i class="fa fa-exchange-alt"></i></span></a>
          <a class="btn btn-info ml-5 inherit btn-heading btn-sm btn-account-details" type="button"  v-if="wallet.automatic" @click="sync()" v-tooltip.top="`Sincronizar`"><span><i class="fa fa-sync"></i></span></a>
          <a class="btn btn-primary  ml-5 inherit btn-heading btn-sm btn-account-details" type="button"  @click="createTransactionIncome('create-transaction-modal')" v-tooltip.top="`Lançamento`" style="border-top-right-radius: 3px; border-bottom-right-radius: 3px;">
            <i class="fa fa-plus"></i>
          </a>
          <div style="clear: both;"></div>
        </div>
        
        <div style="clear: both;"></div>
      </div>
      
    </div>
    <div class="row">
      <div class="col-sm-9" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-3 col-md-3 col-sm-12 hide">
            <label>Filtro</label>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Filtro</label>
          </div>
        </div>
        <div v-for="(filter, n) in filters" class="form-row" :key="n">
          <div class="form-group col-lg-3 col-md-3 col-sm-3 p-right-none">
            <select-input
              v-model="filters[n].field"
              :reduce="(field) => field.value"
              :options="fields"
              :clearable="true"
            />
          </div>
          <div class="form-group col-lg-8 col-md-8 col-sm-8 p-right-none">
            <select-input
              v-if="filters[n].field == 'type'"
              multiple
              :reduce="(field) => field.value"
              label="label"
              v-model="filters[n].values"
              :options="filterAll"
              :clearable="true"
              @input="filterRequest"
            />
            <input
              v-else
              type="text"
              class="form-control"
              placeholder="Selecione um campo"
              disabled
            />
          </div>
          <div class="form-group col-lg-1 hide">
            <button
              @click="removeFilter(n)"
              class="btn btn-danger btn-sm"
              style="height: 40px;"
            >
              <i class="fas fa-trash color-white"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="hidden-xs">&nbsp;</div>
        <div class="input-group mb-3" style="margin-top:5px;">
          <date-range-picker
            class="form-control mr-sm-2"
            value
            :options="optionsDate"
            @change="changeDate"
          />
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-right hide">
        <button @click="addFilter" type="button" class="btn btn-primary">
          <i class="fas fa-plus"></i> Adicionar Filtro
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content relative">
          <div class="cashflow-print" @click="print"><i class="fa fa-print hide"></i></div>
          <div class="tab-pane printable" :class="{'show': tabActive == 'tab-all'}" v-if="tabActive == 'tab-all'">
            <div class="card table-responsive longer" style="border-bottom-right-radius: 10px !important; border-bottom-left-radius: 10px !important;">
              <div class="m-bottom-0">
                <div class="m-bottom-0">
                    <section class="text-center">
                      <h3 class="text-left font-w-100">Saldo do período <br><strong class="total-amount">{{report.transactions.report.total -  report.transactions.report.total_transfer_from | currency}}</strong></h3>
                    </section>
                </div>
                <span class="label label-success info-label-wallet" style="top: 40px !important" v-if="wallet.automatic">Automática</span>     
                <span class="info-label-wallet" style="top: 60px !important; font-size: 13px;" v-if="wallet.automatic">Atualizado em: {{ wallet.last_sync | moment("DD/MM/YYYY H:mm") }}</span>     
              </div>
              <hr>
              
              <h3 class="display-4">Transações</h3>
              <div class="row hidden-xs">
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                  Valor
                </div>
                <div class="col-sm-1 col-lg-1 col-xs-1 text-center">
                  Tipo
                </div>
                <div class="col-sm-2 col-lg-2 col-xs-2">
                  Conta
                </div>
                <div class="col-sm-3 col-lg-3 col-xs-3">
                  Categoria
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 text-center">
                  Data
                </div>
                <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 text-right">
                </div>
              </div>
              <hr>
              <div class="row ng-scope" v-if="report.transactions.transactions.length == 0">
                <img :src="`/assets/images/transaction.svg`" class="nothing-image w-15p" style="width: 13% !important">
                <h3 class="nothing-text">Nenhuma transação encontrada</h3>
              </div>
              <ul class="list-group" v-if="report.transactions.transactions">
                <li class="list-group-item ng-scope" v-for="(item, i) in report.transactions.transactions" :key="i">
                  <div class="row">
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-6">
                      <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                      <span class="text-danger" v-if="item.transaction_type == 'transfer' && item.from_account == wallet.id"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                      <span class="text-success" v-if="item.transaction_type == 'transfer' && item.to_account == wallet.id"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                    </div>
                    <div class="col-sm-1 col-lg-1 col-xs-6 text-right">
                      <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                      <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                      <span class="label label-info" v-if="item.transaction_type == 'transfer'">Transferência</span> 
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12">
                      <strong class="ng-binding" v-if="item.account && item.transaction_type != 'transfer'">{{item.account.name }}</strong>
                      <strong class="ng-binding" v-if="item.to && item.transaction_type == 'transfer' && item.to.id !=  wallet.id">{{item.to.name }}</strong>
                      <strong class="ng-binding" v-if="item.from && item.transaction_type == 'transfer' && item.from.id !=  wallet.id">{{item.from.name }}</strong>
                      <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                    </div>
                    <div class="col-sm-3 col-lg-3 col-xs-12 m-p-top">
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;" v-if="item.transaction_type != 'transfer'">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}`">
                          <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                        </div>
                        &nbsp;{{item.category.name}}
                      </span>
                      <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;" v-if="item.transaction_type == 'transfer'">
                        <div class="icon-category-overview" :style="`margin-right:0px;background: #18b272;`">
                          <img :src="`/assets/images/icons/png/coin.png`" />
                        </div>
                        &nbsp;Transferência
                      </span>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                      <i class="fa fa-credit-card" v-if="item.card"></i>
                    </div>
                    <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12 text-right">
                      <button v-if="item.transaction_type != 'transfer'" type="button" class="btn-edit-category" @click="editTransaction('edit-transaction-modal', item)">
                        <span>
                          <i class="fa fa-cog"></i>
                        </span>
                      </button>
                      <button type="button" class="btn-remove-category" @click="removeTransaction(item)">
                        <span>
                          <i class="fa fa-trash"></i>
                        </span>
                      </button>
                    </div>
                    <div class="col-sm-12 relative" v-if="item.description || item.automatic || item.provider || item.client" style="clear: both; padding-top: 0px;">
                      <hr class="hr-transactions">
                      <span class="ng-binding transaction-note" v-if="item.description">
                        <strong>Descrição</strong> {{item.description }}
                      </span>
                      <span class="ng-binding transaction-note" v-if="item.provider">
                        <strong>Fornecedor</strong> {{ item.provider.name }}
                      </span>
                      <span class="ng-binding transaction-note" v-if="item.client">
                        <strong>Cliente</strong> {{ item.client.name }}
                      </span>
                      <span class="label label-success " style="right: 15px; top: 15px; font-size: 10px; font-weight: 100; !important; position: absolute;" v-if="item.automatic">Automática</span>
                    </div>
                  </div>
                </li>
              </ul>
              <hr>
              <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <br>
      <div class="col-md-12">
        <div class="card radius-10">
          <div class="row">
              <div class="col-md-12">
                <h3 class="text-left font-w-100" style="margin-top: 8px; font-weight: 100;">Relatório</h3>
                <div style="clear: both;"></div>
                <hr>
              </div>
            </div>
          <div class="card-body" style="padding-bottom: 0px;">
            <div class="row">
              <div class="col-md-12">
                <v-chart class="chart-line" :option="optionChart2" />
              </div>
              <div class="col-md-12 figure-stats">
                <div class="figure-section col-md-4" style="border-right: 1px solid #e6e6e6;">
                  <p>Receitas</p>
                  <h2 class="text-primary reports-income font-w-100 income-title-h2">{{boxFlow.income.total | currency}}</h2>
                </div>
                <div class="figure-section col-md-4" style="border-right: 1px solid #e6e6e6;">
                  <p>Despesas</p>
                  <h2 class="text-danger reports-expenses font-w-100 expense-title-h2">{{boxFlow.expense.total | currency}}</h2>
                </div>
                <div class="figure-section col-md-4">
                  <p>Saldo Total</p>
                  <h2 class="reports-total font-w-100" style="margin-bottom: 32px;">{{boxFlow.total.total | currency}}</h2>
                </div>
              </div>
            </div>
            <h5 style="float: right; margin-top: 10px;" class="font-w-100 reports-period">Período: {{ this.from | moment("DD/MM/YYYY") }} até {{ this.to | moment("DD/MM/YYYY") }}</h5>
            <div style="clear: both;"></div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <br>
    <br>
    <edit-account-modal id="edit-account-modal" @save="this.request"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import EditAccountModal from '@/components/EditAccountModal.vue';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { graphic } from 'echarts';
import AppService from '@/services/app.service';
import pace from 'pace-js';
import moment from 'moment-timezone';
import SelectInput from '@/components/SelectInput.vue';
import Swal from 'sweetalert2';
import { moneyFormat } from '@/plugins/functions';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'AccountDetails',
  components: {
    VChart,
    SelectInput,
    EditAccountModal,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      wallet: {},
      cards: [],
      wallets: [],
      filters: [
        {
          field: 'type',
          values: [
            'expense',
            'income',
            'transfer',
          ],
        },
      ],
      fields: [
        { label: 'Tipo', value: 'type' },
      ],
      filterAll: [
        { label: 'Despesas', value: 'expense' },
        { label: 'Receitas', value: 'income' },
        { label: 'Transferências', value: 'transfer' },
      ],
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      tabActive: 'tab-all',
      hourType: 'sun',
      hourTypeText: 'Bom dia',
      totalAccount: 0,
      monthStart: 0,
      from: '',
      to: '',
      report: {
        transactions: {transactions: []},
      },
      boxFlow: {
        income: {},
        expense: {},
        total: {},
        group: {
          all: {
            item: [],
          },
          income: {
            item: [],
          },
          expenses: {
            item: [],
          }
        }
      },
      isLoading: true,
      isLoadingWallet: true,
      isLoadingCards: true,
      optionChart2: {
        legend: {
          data: ['Despesas', 'Receitas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var template = '';
            var balance = '';
            if(params[1]) {
              balance = moneyFormat((params[1].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }
            if(params[0]) {
              balance = moneyFormat((params[0].data));
              template = `${params[0].name} <h1 class="color-black no-margin"> R$ ${balance}</h1>`;
            }

            if(params[1] && params[0]) {
              balance = moneyFormat((params[1].data) - Math.abs(params[0].data));
              template = `${params[1].name} <h1 class="color-black no-margin"> R$ ${balance}</h1> <hr style="margin-top:5px;margin-bottom:5px;"> <p style="margin:0px; color: #cccccc !important;">Entradas: <span style="text-align: right; display:block; float: right;"> R$ ${moneyFormat(params[1].data)}</span></p><p style="color: #cccccc !important;">Saídas: <span style="text-align: right; display:block; float: right;">R$ ${moneyFormat(params[0].data)}</span></p>`;
            }
            return template;
          }
        },
        xAxis: {
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 0,
          },
          data: [],
          silent: false,
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [{
            name: 'Despesas',
            type: 'line',
            smooth: false,
            stack: 'transactions',
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(255,26,26,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(255,26,26,0.15)'
                },{
                    offset: 1,
                    color:'rgba(255,26,26,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#ff1a1a",
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 10;
            }
        }, {
            name: 'Receitas',
            type: 'line',
            stack: 'transactions',
            barMaxWidth: 10,
            smooth: false,
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(55,163,59,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(55,163,59,0.15)'
                },{
                    offset: 1,
                    color:'rgba(55,163,59,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#18b272"
              }
            },
            data: [
              0, 0, 0, 0, 0
            ],
            animationDelay: function (idx) {
              return idx * 10 + 100;
            }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  created() {
    moment.locale('pt_BR');
    this.monthStart = new Date().getMonth();
    console.log('Mês', this.monthStart);
    pace.start();
    
    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
    this.optionsDate.startDate = `${moment(new Date()).format('01/MM/YYYY')}`;
    this.optionsDate.endDate = moment(this.from, "YYYY-MM-DD").endOf('month').format('DD/MM/YYYY');
    this.$root.$on('register.transaction', this.request);
    this.request();
  },
  methods: {
    sync() {
      Swal.fire({
        title: 'Sincronizando',
        html: 'Por favor, aguarde...',
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          console.log('I was closed by the timer')
        }
      });
      AppService.nubankSync({
        account: this.wallet.id
      }).then(
        () => {
          this.isSending = false;
          this.$toast.show({
            title: "Sucesso",
            content: "Conta sincronizada com o banco!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    goImport(account) {
      this.$router.push(`/import`, { params: { wallet: account} });
    },
    removeAccount() {
      Swal.fire({
        title: `Remover conta?`,
        text: 'Tem certeza que deseja remover essa conta?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteAccount();
        }
      });
    },
    deleteAccount() {
      AppService.deleteAccount(this.wallet.id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Conta removida!",
            type: "success",
          });
          this.$router.go(-1);
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    editAccount(id) {
      this.$root.$emit('show.modal', id, this.wallet);
    },
    deleteTransaction(id) {
      AppService.deleteTransaction(id).then(
        (response) => {
          this.isSending = false;
          console.log(response);
          this.$toast.show({
            title: "Sucesso",
            content: "Transação removida!",
            type: "success",
          });
          this.request();
        },
        (error) => {
          console.log(error);
          this.content = error;
          this.isSending = false;
          this.$toast.show({
            title: "Erro",
            content: "Erro interno, por favor tente novamente mais tarde",
            type: "error",
          });
        }
      );
    },
    removeTransaction(item) {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover essa transação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.deleteTransaction(item.id);
        }
      });
    },
    editTransaction(id, item) {
      let data      = item;
      data.date     = moment(data.transaction_date, "YYYY-MM-DD").format('DD/MM/YYYY');
      data.value    = item.amount;
      data.note     = item.description;
      data.parent   = item.category.parent;
      this.$root.$emit('show.modal', id, data);
    },
    createTransaction(id) {
      const data = {
        isCard: false,
        type: 'transfer',
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        transfer: true,
        invoice: null,
        from: this.wallet.id,
        account: null,
        check_sub: true,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    createTransactionIncome(id) {
      const data = {
        isCard: false,
        type: 'expense',
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        transfer: false,
        invoice: null,
        account: this.wallet.id,
        check_sub: true,
        date: moment(new Date()).format('DD/MM/YYYY'),
      };
      this.$root.$emit('show.modal', id, data);
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
    request() {
      AppService.getCards({
        from: this.from,
        to: this.to,
      }).then(
        (response) => {
          console.log('Cards');
          console.log(response);
          this.cards = response.cards;
          this.isLoadingCards = false;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getWallets({}).then(
        (response) => {
          this.isLoadingWallet = false;
          this.wallets = response.wallets;
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.getWallet(this.$route.params.id).then(
        (response) => {
          this.isLoadingWallet = false;
          this.wallet = response.wallet;
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
      
      AppService.getInfo({
        from: this.from,
        to: this.to,
        no_card: true,
        filter: this.filters,
        account_id: this.$route.params.id,
      }).then(
        (response) => {
          this.isLoading = false;
          console.log(response);
          this.report = response;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );

      AppService.boxFlow({
        from: this.from,
        to: this.to,
        get_group: true,
        filter: this.filters,
        account_id: this.$route.params.id,
        status: 1,
      }).then(
        (response) => {
          console.log('Box flow');
          console.log(response);
          this.optionChart2.xAxis.data = response.chart.label;
          this.optionChart2.series[0].data = response.chart.expense;
          this.optionChart2.series[1].data = response.chart.income;
          this.boxFlow = response;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    addFilter() {
      this.filters.push({
        field: '',
        values: null,
      });
    },
    removeFilter(n) {
      this.filters.splice(n, 1);
    },
    print () {
      window.print();
    },
    filterRequest() {
      this.request();
    },
    navigateDateTo (month) {
      console.log(month);
      this.from = `${moment(new Date()).format('YYYY')}-${month.id}-01`;
      this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
      AppService.boxFlow({
        from: this.from,
        to: this.to,
        get_group: true,
      }).then(
        (response) => {
          console.log(response);
          this.boxFlow = response;
          this.optionChart2.xAxis.data = this.boxFlow.chart.label;
          this.optionChart2.series[0].data = this.boxFlow.chart.expense;
          this.optionChart2.series[1].data = this.boxFlow.chart.income;
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.page-heading {
  margin-bottom: 0px;
}
.chart {
  height: 200px;
}
.chart-line {
  height: 350px;
  margin-top: 0px;
}
.swiper-slide{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.swiper-container {
  height : 500px;
}
.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}
.card-header {
  border-bottom: none;
}
.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}
.heading-title {
  padding-left: 0px;
}
.total-amount {
  margin-top: 10px;
  display: block;
}
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.figure-stats {
  border-right: none;
  border-left: none;
}

.drop-down {
 position:relative
}
.drop-down.arrow-left .drop-down-box:after {
 left:20px;
 right:initial
}
.drop-down.arrow-center .drop-down-box:after {
 left:64%;
 right:initial;
 margin-left:-6px
}
.drop-down.arrow-right .drop-down-box:after {
 right:20px;
 left:initial
}
.drop-down .drop-down-box {
 display:none;
 position:absolute;
 margin-top:4px;
 left: -35px;
 top: 33px;
 background:#fff;
 -moz-box-shadow:0 1px 6px rgba(0,0,0,.17);
 -webkit-box-shadow:0 1px 6px rgba(0,0,0,.17);
 box-shadow:0 1px 6px rgba(0,0,0,.17);
 width: 120px;
 z-index:2;
 -moz-border-radius:2px;
 -webkit-border-radius:2px;
 border-radius:2px
}
.drop-down .drop-down-box .scroll-group>ul,
.drop-down .drop-down-box ul {
 padding:12px 0;
 list-style: none;
}
.drop-down .drop-down-box .scroll-group>ul li a,
.drop-down .drop-down-box ul li a {
 padding:6px 30px;
 font-size:14px;
 display:block;
 color:#777;
 text-align:left;
 letter-spacing:0;
 -webkit-transition:all .15s ease-out 0s;
 -moz-transition:all .15s ease-out 0s;
 -o-transition:all .15s ease-out 0s;
 transition:all .15s ease-out 0s
}
.drop-down .drop-down-box .scroll-group>ul li a:hover,
.drop-down .drop-down-box ul li a:hover {
 color:#0a914b
}
.drop-down .drop-down-box .scroll-group>ul li.active>a,
.drop-down .drop-down-box ul li.active>a {
 color:#0a914b
}
.drop-down .drop-down-box .scroll-group>ul li:last-child,
.drop-down .drop-down-box ul li:last-child {
 border-bottom:none
}
.drop-down .drop-down-box .scroll-group>ul li.logout a:hover,
.drop-down .drop-down-box ul li.logout a:hover {
 color:#ff7d78
}
.drop-down .drop-down-box .scroll-group>ul li ul,
.drop-down .drop-down-box ul li ul {
 padding:0
}
.drop-down .drop-down-box .scroll-group>ul li ul li a,
.drop-down .drop-down-box ul li ul li a {
 padding:6px 30px 6px 45px
}
.drop-down .drop-down-box .actions {
 display:block;
 padding:0 20px 20px
}
.drop-down .drop-down-box .actions:after {
 content:".";
 display:block;
 height:0;
 clear:both;
 visibility:hidden
}
* html .drop-down .drop-down-box .actions {
 height:1px
}
.drop-down .drop-down-box .actions a {
 float:left;
 margin-right:11px;
 padding:0 12px 0 0;
 border-right:1px solid #8d979b;
 text-transform:lowercase;
 font-size:13px
}
.drop-down .drop-down-box .actions a:last-child {
 margin-right:0;
 padding:0;
 border-right:none
}
.drop-down .drop-down-box:after {
 display:block;
 content:'';
 border-left:6px solid transparent;
 border-right:6px solid transparent;
 border-bottom:6px solid #fff;
 position:absolute;
 top:-6px;
 left:50%;
 margin-left:-6px
}
.drop-down .drop-down-box .loading {
 padding:0
}
.drop-down .drop-down-box .loading img {
 height:40px
}
.drop-down .scroll-group {
 max-height:400px;
 overflow:auto
}
.drop-down.black .drop-down-box {
 background:rgba(231,232,234,.94);
 -moz-box-shadow:0 1px 1px rgba(0,0,0,.18);
 -webkit-box-shadow:0 1px 1px rgba(0,0,0,.18);
 box-shadow:0 1px 1px rgba(0,0,0,.18)
}
.drop-down.black .drop-down-box>.scroll-group>ul li.sub-title,
.drop-down.black .drop-down-box>ul li.sub-title {
 padding:20px 0 10px 10px
}
.drop-down.black .drop-down-box>.scroll-group>ul li.active>a,
.drop-down.black .drop-down-box>ul li.active>a {
 background:#fff url('/assets/images/list-active.png') no-repeat 94% center!important
}
.drop-down.black .drop-down-box>.scroll-group>ul li a,
.drop-down.black .drop-down-box>ul li a {
 color:#8d8f96
}
.drop-down.black .drop-down-box>.scroll-group>ul li a:hover,
.drop-down.black .drop-down-box>.scroll-group>ul li a.hover,
.drop-down.black .drop-down-box>ul li a:hover,
.drop-down.black .drop-down-box>ul li a.hover {
 background:#e0e1e3;
 color:#717373
}
.drop-down.black .drop-down-box>p {
 display:block;
 padding:15px;
 color:#8d8f96
}
.drop-down.black .drop-down-box>p a {
 font-size:13px!important
}
.drop-down.black .drop-down-box:after {
 border-bottom:6px solid #e7e8ea
}
.drop-down-transaction:hover .drop-down-box {
  display: block !important;
}
.btn-account-details {
  padding: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-left: 5px !important;
}
@media (max-width: 667px) {
  .btn-account-details {
    padding: 10px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    margin-left: 0px !important;
  }
}
</style>
